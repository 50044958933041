import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FieldPathDirective, FieldType, FormFieldComponent, FormOptionsDirective, TextArea } from '@bo/ng-forms';
import { CurrencyMaskConfig } from 'ngx-currency';

const INPUT_INVALID_CLASS = 'is-invalid';
const INPUT_VALID_CLASS = 'is-valid';

enum DateTimeFieldType {
  datePicker,
  dateTimePicker,
  dateRange
}

@Component({
  selector: 'app-form-field',
  templateUrl: './field.component.html',
  styleUrls: [],
  providers: [
    {provide: FormFieldComponent, useExisting: forwardRef(() => FieldComponent)}
  ]
})
export class FieldComponent extends FormFieldComponent implements OnInit {

  DateTimeFieldType = DateTimeFieldType;

  /** Override the label of the field */
  @Input() label: string;
  @Input() smallLabel: boolean;
  /** If a label should be shown instead of a placeholder */
  @Input() hideLabel: boolean;
  /** To set the `autocomplete` property of the input. */
  @Input() autocomplete: string;
  /** Switches an FieldType.input from an input to textarea */
  @Input() textArea: TextArea;
  /** Min/Max Dates for Datepickers */
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() minValue: number;
  /** Uses a date range picker for a datetime field */
  @Input() useDateRange: boolean;
  /** Uses a time picker for a datetime field */
  @Input() useDateTimePicker: boolean;
  /** Text that should be prepended to an input as an Input Group */
  @Input() inputGroupText: string;
  @Input() addCurrencyMask: boolean;
  @Input() currencyMaskOptions: CurrencyMaskConfig;
  /** Template to be displayed below the field label. */
  @Input() labelBody: string;
  @Output() labelBodyClicked = new EventEmitter<MouseEvent>();
  @Input() disabled: boolean;
  @Input() prefix: string;
  @Input() fieldToolTip: string;
  @Input() isFloating: boolean = true;
  @Input() isFloatingSmall: boolean = true;
  @Input() showPlaceholder: boolean;
  @Input() fieldClass: string;
  @Input() borderClass: string;
  @Input() type?: FieldType;
  @Input() maxLength: number;
  @Input() placeholder: string;
  @Input() allowDecimal?: boolean;
  @Input() allowNegative?: boolean;
  @Input() dataId: string;

  get fieldType(): FieldType {
    return this.type ?? this.fieldOption.type;
  }

  constructor(
    protected formOptionsDirective: FormOptionsDirective,
    protected fieldPathDirective: FieldPathDirective) {
    super(formOptionsDirective, fieldPathDirective);
  }

  bodyClicked(event: MouseEvent) {
    this.labelBodyClicked.emit(event);
  }

  /**
   * Return the class name for the input depends on if the field is valid or invalid.
   */
  getValidityClass(): string {
    if (this.fieldControl && this.fieldControl.errors && (this.fieldControl.touched || this.fieldControl.dirty)) {
      return INPUT_INVALID_CLASS;
    } else {
      return INPUT_VALID_CLASS;
    }
  }

  // Manual intervention to not allow mathematical number chars in the input field as it triggers incorrect error messages.
  // For example: +, - and e and in some cases comma's and periods.
  detectInvalidNumChar(event) {
   if (event.target.getAttribute('type') === "number") {
     const defaultAllow = ['Tab', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
     if (this.allowDecimal) {
       return [...defaultAllow, 'Comma', 'Period', 'NumpadDecimal']
         .includes(event.code) ? true : !isNaN(Number(event.key)) && event.code !== 'Space'
     } else if (this.allowNegative) {
       return [...defaultAllow, 'NumpadSubtract', 'Minus']
         .includes(event.code) ? true : !isNaN(Number(event.key)) && event.code !== 'Space'
     } else {
       return [...defaultAllow]
         .includes(event.code) ? true : !isNaN(Number(event.key)) && event.code !== 'Space'
     }
   }
  }

  get dateTimeFieldType(): DateTimeFieldType {
    if (!this.useDateTimePicker && !this.useDateRange) {
      return DateTimeFieldType.datePicker;
    }
    if (this.useDateTimePicker) {
      return DateTimeFieldType.dateTimePicker;
    }
    if (this.useDateRange) {
      return DateTimeFieldType.dateRange;
    }
    return null;
  }
}
