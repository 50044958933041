import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import * as Raven from 'raven-js';
import { AppState } from './store/states';
import { AuthState } from './shared/auth/store/states';
import { RootComponent } from './root.component';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SentryTestComponent } from './sentry-test/sentry-test.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HttpClientXsrfModule, HttpErrorResponse } from '@angular/common/http';
import { AuthApi, BoAuthModule } from '@bo/ng-auth';
import { BoFormsModule } from '@bo/ng-forms';
import { BoBreadcrumbsModule } from '@bo/ng-breadcrumbs';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgSelectModule } from '@ng-select/ng-select';
import { BoEnumsModule } from '@bo/ng-enums';
import { BoUploadsModule } from '@bo/ng-uploads';
import { DatatableModule } from '@bo/ng-datatable';
import { SwiperModule } from 'swiper/angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ReportApiService } from './shared/api/services/report-api.service';
import { ConfigService } from './shared/api/services/config.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { environment } from '../environments/environment';
import { SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ApiService } from './shared/api/services/api.service';
import { AuthApiService } from './shared/api/services/auth-api.service';
import { AppLoginModalComponent } from './shared/components/modals/login/login-modal.component';
import { DocumentService } from './modules/roc/pages/documents/services/document.service';
import { DocumentsApiService } from './shared/api/services/documents-api.service';
import { GearApiService } from './modules/roc/pages/gear/services/gear-api.service';
import { OnboardingWrapperComponent } from './pages/onboarding/onboarding-wrapper.component';
import { AccountTypeComponent } from './pages/onboarding/register/account-type/account-type.component';
import { RegisterComponent } from './pages/onboarding/register/personal-details/register.component';
import { VerifyEmailComponent } from './pages/onboarding/verify-email/verify-email.component';
import { EmailVerificationComponent } from './pages/onboarding/email-verification/email-verification.component';
import { ROCCreateComponent } from './pages/onboarding/register/roc-details/roc-create.component';
import { AcceptInvitationComponent } from './pages/onboarding/accept-invitation/accept-invitation.component';
import { LoginComponent } from './pages/onboarding/login/login.component';
import { PasswordResetRequestComponent } from './pages/onboarding/password-reset-request/password-reset-request.component';
import { PasswordResetConfirmComponent } from './pages/onboarding/password-reset-confirm/password-reset-confirm.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OperationApiService } from './modules/roc/pages/operations/services/operation-api.service';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ResizableModule } from 'angular-resizable-element';
import { LoginRocNotFoundModalComponent } from './pages/onboarding/_components/modals/login-roc-not-found/login-roc-not-found.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MeetingsApiService } from './modules/roc/pages/calendar/services/meetings-api.service';
import { FreeTrialModalComponent } from './pages/onboarding/_components/modals/free-trial/free-trial.component';
import { UnsavedChangesGuard } from './shared/guards/unsaved-changes.guard';
import { RouterModule } from '@angular/router';
import { TourNgxBootstrapModule } from 'ngx-ui-tour-ngx-bootstrap';
import { SubscriptionApiService } from './modules/roc/pages/roc/subscription/services/subscription-api.service';
import { SubscriptionService } from './modules/roc/pages/roc/subscription/services/subscription.service';

export function configFactory(config: ConfigService) {
  return () => config.load();
}

Raven
  .config(environment.ravenDsn, {
    shouldSendCallback: () => environment.production,
    environment: environment.environment,
    release: environment.version
  })
  .install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    // Never log HTTP Client errors to Sentry.
    if (!(err instanceof HttpErrorResponse)) {
      Raven.captureException(err);
    }
  }
}


@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    AppLoginModalComponent,
    OnboardingWrapperComponent,
    AccountTypeComponent,
    ROCCreateComponent,
    SentryTestComponent,
    LoginComponent,
    LoginRocNotFoundModalComponent,
    RegisterComponent,
    VerifyEmailComponent,
    AcceptInvitationComponent,
    EmailVerificationComponent,
    PasswordResetRequestComponent,
    PasswordResetConfirmComponent,
    FreeTrialModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FullCalendarModule,
    SharedModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 8000,
      positionClass: 'toast-bottom-left',
      toastClass: 'toast show',
      messageClass: 'toast-body',
      titleClass: 'toast-header',
      extendedTimeOut: 5000,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    BoBreadcrumbsModule.forRoot(),
    PaginationModule.forRoot(),
    BoAuthModule.forRoot({
      loginUrl: '/login',
      loginModalComponent: AppLoginModalComponent,
      jwtAuthHeaderPrefix: 'Token',
      apiRoot: environment.apiRoot
    }),
    BoFormsModule.forRoot(),
    BoUploadsModule.forRoot({
      apiBaseUrl: environment.apiRoot,
      apiInfoUrl: 's3',
      apiPrepUrl: 's3/prep'
    }),
    BoEnumsModule.forRoot({
      apiBaseUrl: `${environment.apiRoot}enums/`,
      apiEndpointType: 'single',
      singleEndpointPath: 'enums-list'
    }),
    SwiperModule,
    ResizableModule,
    // ServerErrorModule,
    LoadingBarRouterModule,
    HttpClientModule,
    // https://angular.io/guide/http#configuring-custom-cookieheader-names
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
    PdfViewerModule,

    // Needs to be after our app modules.
    AppRoutingModule,
    RouterModule,
    TourNgxBootstrapModule.forRoot(),

    NgxsModule.forRoot([AuthState, AppState], {
      developmentMode: !environment.production,
      selectorOptions: {suppressErrors: false}
    }),
    NgxsStoragePluginModule.forRoot({key: ['auth', 'inFlight', 'notifications', 'auditReport']}),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({name: 'flytezone', disabled: environment.production}),
    NgxsLoggerPluginModule.forRoot({disabled: environment.production}),

    // BO-Datatables
    DatatableModule,
  ],
  providers: [
    ConfigService,
    BsModalService,
    BsModalRef,
    Title,
    {
      provide: LOCALE_ID,
      useValue: 'en-ZA'
    },
    {
      provide: SWIPER_CONFIG,
      useValue: {
        direction: 'horizontal',
        slidesPerView: 'auto'
      } as SwiperConfigInterface,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    // Services
    ApiService,
    {
      provide: AuthApi,
      useClass: AuthApiService,
    },
    // Guards
    UnsavedChangesGuard,
    DocumentService,
    DocumentsApiService,
    GearApiService,
    ReportApiService,
    OperationApiService,
    MeetingsApiService,
    SubscriptionService,
    SubscriptionApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
